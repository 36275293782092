<template>
  <b-card>
      <!-- form -->
      <b-button @click="addNew()"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="warning"
        size="sm"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-50"
        />
        <span class="align-middle">{{ $t('Add Result') }}</span>
      </b-button>
      <b-form class="mt-2">
        <b-row>
          <b-col cols="12" md="12" lg="12">
            <b-table
              :fields="fields"
              :items="results"
              small
            >
              <template #cell(action)="data">
                <b-button
                  variant="primary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  @click="update(data.item)"
                  size="sm">
                  <feather-icon
                    icon="EditIcon"
                    class="mr-50"
                    size="16"
                  />
                  {{ $t('Update') }}
                </b-button>

                <!-- Remove -->
                <feather-icon
                  @click="deleteOrder(data.item.id)"
                  icon="Trash2Icon"
                  class="cursor-pointer mx-1 text-danger"
                  size="16"
                />
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-form>
      <SaveOrderResult v-if="modalObject.showModal" :modalObject="modalObject"/>
  </b-card>
</template>

<script>
import Ripple from 'vue-ripple-directive'
// Alert Toast
import AlertService from '@/common/alert.service'
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'

import SaveOrderResult from './SaveOrderResult.vue'

export default {
  components: {
    SaveOrderResult,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: [
        {
          key: 'resultTitle',
          label: 'resultTitle',
        },
        {
          // A regular column with custom formatter
          key: 'resultDescription',
          label: 'Desc',
        },
        {
          // A virtual column with custom formatter
          key: 'orderResultStatusLabel',
          label: 'Status',
        },
        {
          // A regular column with custom formatter
          key: 'dataVersion',
          label: 'dataVersion',
        },
        {
          // A virtual column with custom formatter
          key: 'action',
          label: 'action',
          class: 'text-right',
        },
      ],

      modalObject: {
        item: {},
        showModal: false,
        onSuccess: this.fetchItem,
      },
    }
  },
  methods: {
    addNew() {
      this.modalObject.item = { orderResultStatus: 1, orderId: this.$router.currentRoute.params.id };
      this.modalObject.showModal = true;
    },
    update(item) {
      Object.assign(this.modalObject.item, item);
      this.modalObject.showModal = true;
    },

    fetchItem() {
      const paramId = this.$router.currentRoute.params.id;
      if (parseInt(paramId, 10) !== 0) {
        this.$store.dispatch('orderManagement/fetchOrder', { id: paramId });
      }
    },

    deleteOrder(id) {
      AlertService.confirmDelete(this, () => {
        this.$store.dispatch('orderManagement/removeOrderResult', { id })
            .then(response => {
              if (response.statusCode === 200) {
                this.$toast({
                  component: ToastificationSuccess,
                  position: 'top-right',
                  props: { text: this.$t(response.message) },
                });

                this.fetchItem();
              } else {
                AlertService.error(this, this.$t(response.message))
              }
            });
      });
    },
  },
  computed: {
    results() {
      return this.$store.getters['orderManagement/getOrderResults']
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
.source-input {
    box-sizing: border-box;
    margin: 0px;
    outline: 0px;
    padding: 1em;
    height: calc(100% - 3em);
    min-height: 3em;
    width: 100%;
    overflow: auto;
    color: inherit;
    font-size: 0.7em;
    white-space: pre;
}
</style>
