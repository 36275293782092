<template>
  <div>
    <b-modal
      ref="modal-refun-payment"
      title="Refund Payment"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      @hidden="hiddenModal"
      size="md"
    >
      <b-form>
        <b-row>
          <b-col cols="12" md="12" lg="12">
            <b-form-group :label="$t('Payment Intent')" label-for="refundData.paymentIntent">
              <b-form-input id="refundData.paymentIntent" v-model="refundData.paymentIntent" readonly/>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="12" lg="12">
            <b-form-group :label="$t('Refund Amount')" label-for="refundData.refundAmount">
              <b-form-input id="refundData.refundAmount" v-model="refundData.refundAmount" type="number"/>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="12" lg="12">
            <b-form-group :label="$t('Refund Notification Content')" label-for="refundNotificationContent">
              <b-form-textarea
                id="refundNotificationContent"
                v-model="refundNotificationContent"
                class="source-input"
                placeholder="Refund Notification Content"
                rows="2"
                no-auto-shrink
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer>
        <b-button variant="primary" class="float-right" @click="handleOk">
          <b-spinner
            v-if="$store.getters.getLoader"
            class="mr-1"
            small
            variant="light"
          />
          <feather-icon
            v-else
            icon="UploadCloudIcon"
            class="mr-50"
          />
          {{ $t("Refund") }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import AlertService from '@/common/alert.service'
import ApiService from '@/common/api.service'

// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'

export default {
  components: {
  },
  props: {
    modalObject: {
      type: Object,
    },
    callback: {
      type: Function,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      refundData: {
        paymentId: 0,
        paymentIntent: null,
        refundAmount: 0,
      },
      /* eslint-disable */
      refundNotificationContent: "Successfully refunded. It may take a few days for the money to reach the customer's bank account.",
    }
  },
  methods: {
    uploadToDrive() {
      const query = {
        paymentId: this.refundData.paymentId,
        paymentIntent: this.refundData.paymentIntent,
        refundAmount: this.refundData.refundAmount * 100,
        content: this.refundNotificationContent,
      }
      ApiService.post('Payment/refund', query, this)
        .then(response => {
          if (response.statusCode === 200) {
            this.$toast({
              component: ToastificationSuccess,
              position: 'top-right',
              props: { text: this.$t(response.message) },
            });
            this.modalObject.onSuccess();
            this.hiddenModal();
          } else {
            AlertService.error(this, this.$t(response.message))
          }
        })
    },

    initialModal() {
      this.refundData = this.modalObject.item;
      this.$refs['modal-refun-payment'].show();
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.uploadToDrive()
    },
    hiddenModal() {
      this.$refs['modal-refun-payment'].hide()
      this.modalObject.showModal = false;
    },
  },
  mounted() {
    this.initialModal();
  },
};
</script>
