<template>
  <b-card>
      <!-- form -->
      <b-form class="mt-2">
        <b-row>
          <b-col cols="12" md="8" lg="8">
            <b-row>
              <b-col cols="12" md="6" lg="6">
                <b-form-group :label="$t('Order No')" label-for="orderNo">
                  <b-form-input id="orderNo" v-model="orderNo" readonly/>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <b-form-group :label="$t('Order No')" label-for="orderNo">
                  <b-form-input id="orderDate" :value="formatDateTime(orderDate)" readonly/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6" lg="6">
                <b-form-group :label="$t('Order Title')" label-for="orderTitle">
                  <b-form-input id="orderTitle" v-model="orderTitle" readonly/>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <b-form-group
                  label=""
                  label-for="contact-options"
                  label-class="mb-1">
                  <div class="demo-inline-spacing">
                    <b-form-checkbox
                      v-model="isActive"
                      class="custom-control-primary">
                      {{ $t('Active')}}
                    </b-form-checkbox>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6" lg="6">
                <b-form-group :label="$t('User Name')" label-for="user.fullName">
                  <b-form-input id="user.fullName" v-model="user.fullName" readonly/>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <b-form-group :label="$t('User Name')" label-for="user.userName">
                  <b-form-input id="user.userName" v-model="user.userName" readonly/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6" lg="6">
                <b-form-group :label="$t('Sub Total')" label-for="subtotal">
                  <b-form-input id="subtotal" v-model="subtotal" readonly/>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <b-form-group :label="$t('Currency Unit')" label-for="currencyUnit">
                  <b-form-input id="currencyUnit" v-model="currencyUnit" readonly/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" md="4" lg="4">
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-form-group :label="$t('Order Status')" label-for="orderStatus">
                  <v-select
                    v-model="orderStatus"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="orderStatusOptions"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    input-id="orderStatus"
                    placeholder="Select Status"/>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="12" lg="12" v-if="orderStatus === 4">
                <b-form-group :label="$t('Order Cancel Reason')" label-for="orderCancelReasonType">
                  <v-select
                    v-model="orderCancelReasonType"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="orderCancelReasonOptions"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    input-id="orderCancelReasonType"
                    placeholder="Select Status"/>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="12" lg="12">
                <b-form-group :label="$t('Payment Type')" label-for="paymentData_paymentType">
                  <v-select
                    v-model="paymentData.paymentType"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="paymentTypeOptions"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    input-id="paymentData_paymentType"
                    placeholder="Select Payment Type" :disabled="true"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" md="4" lg="4">
            <b-form-group v-if="paymentData.paymentSuccessObject" :label="$t('Payment Intent')" label-for="paymentData_paymentIntent">
              <b-form-input id="paymentData_paymentIntent" :value="paymentData.paymentSuccessObject.data.object.payment_intent" readonly/>
            </b-form-group>

            <b-form-group v-else :label="$t('Payment No')" label-for="paymentData_paymentNo">
              <b-form-input id="paymentData_paymentNo" v-model="paymentData.paymentNo" readonly/>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4" lg="4">
            <b-form-group :label="$t('Payment Amount')" label-for="paymentData_paymentAmount">
              <b-form-input id="paymentData_paymentAmount" :value="paymentData.paymentAmount/100"/>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4" lg="4">
            <b-form-group :label="$t('Payment Status')" label-for="payment_status">
              <div v-if="!paymentData.isRefunded">
                <b-badge pill v-if="paymentData.isComplete" variant="success">
                  <span class="align-text-top text-capitalize">{{ 'SUCESSFUL' }}</span>
                </b-badge>
                <b-badge pill v-else variant="warning">
                  <span class="align-text-top text-capitalize">{{ 'UNSUCESSFUL' }}</span>
                </b-badge>
              </div>
              <div v-else>
                <b-badge pill v-if="paymentData.refundAmount === paymentData.paymentAmount" variant="success">
                  <span class="align-text-top text-capitalize">{{ 'Refunded' }}</span>
                </b-badge>
                <b-badge pill v-else variant="warning">
                  <span class="align-text-top text-capitalize">{{ 'Partial Refund' }}</span>
                </b-badge>
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4" lg="4" v-if="paymentData.isRefunded">
            <b-form-group :label="$t('Refund Amount')" label-for="paymentData_refundAmount">
              <b-form-input id="paymentData_refundAmount" :value="paymentData.refundAmount /100"/>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="6">
            <save-button 
              :onClickSave="saveItem"
              :showSaveAndClose="false"
              />
          </b-col>
          <b-col cols="6" class="mt-2 d-flex justify-content-end">
            <b-button variant="success" class="mb-1 mb-sm-0 mr-0 mr-sm-1" @click="openRefunModal()" size="sm">
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                  size="16"
                />
                {{ $t('Refund Payment') }}
              </b-button>
          </b-col>
        </b-row>

      </b-form>

      <RefundPaymentModal v-if="refundModalObject.showModal" :modalObject="refundModalObject"/>
  </b-card>
</template>

<script>
import { formatDateTime } from '@core/utils/filter'
// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'
// Alert
import AlertService from '@/common/alert.service'
import { createHelpers } from 'vuex-map-fields';
import Ripple from 'vue-ripple-directive'
import SaveButton from '@/components/SaveButton.vue';
import StaticOptions from '@/common/options/StaticOptions';

import RefundPaymentModal from '../../orders/modals/RefundPaymentModal.vue';

const { mapFields } = createHelpers({
  getterType: 'orderManagement/getOrderField',
  mutationType: 'orderManagement/updateOrderField',
});

export default {
  components: {
    SaveButton,
    RefundPaymentModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      formatDateTime,
      orderStatusOptions: StaticOptions.orderStatusOptions,
      orderCancelReasonOptions: StaticOptions.orderCancelReasonOptions,
      paymentTypeOptions: StaticOptions.paymentTypeOptions,
      paymentData: {},

      refundModalObject: {
        item: {},
        showModal: false,
        onSuccess: this.fetchOrderPayment,
      },
    }
  },
  methods: {
    fetchOrderPayment() {
      this.$store.dispatch('paymentManagement/fetchPayment', { id: this.paymentId })
        .then(response => {
          if (response.statusCode === 200) {
            if (response.result.paymentSuccessJson) {
              response.result.paymentSuccessObject = JSON.parse(response.result.paymentSuccessJson)
            }
            
            this.paymentData = response.result;
          } else {
            AlertService.error(this, response.message)
          }
        });
    },
    saveItem() {
      if (this.paymentData.paymentType === 1 && this.orderStatus === 3) {
        this.paymentData.isComplete = true;
      }

      if (this.paymentData.paymentType === 2 && this.orderStatus === 3 && this.paymentData.isComplete !== true) {
        AlertService.error(this, 'Payment transaction was not successful')
        return;
      }

      const queryParam = {
        orderId: this.id,
        orderStatus: this.orderStatus,
        orderCancelReasonType: this.orderCancelReasonType,
      };

      this.$store.dispatch('orderManagement/saveOrder', queryParam)
        .then(response => {
          if (response.statusCode === 200) {
            this.$toast({
              component: ToastificationSuccess,
              position: 'top-right',
              props: { text: response.message },
            });

            this.savePayment();
          } else {
            AlertService.error(this, response.message)
          }
        });
    },
    savePayment() {
      this.$store.dispatch('paymentManagement/savePayment', this.paymentData)
        .then(response => {
          if (response.statusCode === 200) {
            this.$toast({
              component: ToastificationSuccess,
              position: 'top-right',
              props: { text: response.message },
            })
          } else {
            AlertService.error(this, response.message)
          }
        });
    },

    openRefunModal() {
      this.refundModalObject.item = {
        paymentId: this.paymentData.id,
        paymentIntent: this.paymentData.paymentSuccessObject.data.object.payment_intent,
        refundAmount: (this.paymentData.paymentAmount - this.paymentData.refundAmount) / 100,
      }
      this.refundModalObject.showModal = true;
    },
  },
  computed: {
    ...mapFields([
      'id',
      'isActive',
      'orderNo',
      'subtotal',
      'currencyUnit',
      'orderTitle',
      'user',
      'paymentId',
      'presetRegion',
      'paymentTypeLabel',
      'orderStatus',
      'orderCancelReasonType',
      'orderDate',
    ]),
  },
  mounted() {
    this.fetchOrderPayment();
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
